import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Kanit', 'sans-serif';
  }
  
  html, body, #__next {
    min-height: 100%;
    min-width: 100%;
    display: flex;
  }

  html {
    background: #262626;
  }

  body {
    &::before {
      content: '';
      position: fixed;
      width: 100vw;
      height: 100vh;
      background-image: url('/assets/bg2.png');
      background-repeat: no-repeat;
      background-size: cover;
    }
    img {
      height: auto;
      max-width: 100%;
    }
  }

  #__next {
    position: relative;
    z-index: 1;
  }

  #portal-root {
    position: relative;
    z-index: 2;
  }

  .position-type {
    background: #8500ff;
    font-size: 0.7em;
    padding: 0 0.5em;
    border-radius: 0.5em;
  }

  .position-range {
    background: #fff3;
    color: white;
    font-size: 0.7em;
    padding: 0 0.5em;
    border-radius: 0.5em;
    margin-top: -0.4em;
    width: fit-content;
    text-transform: capitalize;
  }
`

export default GlobalStyle
